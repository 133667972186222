import React from "react";
export default function PageBackground() {
  return (
    <>
      <img
        className="inline__image"
        src="https://source.unsplash.com/1000x1800/?travel,boho,sunset&auto=format&fit=crop&w=1492&q=40"
        alt="background images roates through image catagories of travel, festival and summer"
      />
    </>
  );
}
